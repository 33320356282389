
/* You can add global styles to this file, and also import other style files */
// @import url('https://db.onlinewebfonts.com/c/6fee6b87eeb14b28f13efcb6c136c7b4?family=Proxima+Sans+Medium+Regular');
// colors
$primary-color:#23608C;
$white-color:#ffffff;
$action-btn-background:#0A65CC;
$black-color:#101010;
$default-color:#666666;
$border-color:#DADEE3;

//styleName: Primary font;
// @font-face {
//     font-family: "Nunito-Sans";
//     src: url("../src/assets/NunitoSans-ExtraLight.ttf");
//     src: url("../src/assets/NunitoSans-Light.ttf");
//     src: url("../src/assets/NunitoSans-Regular.ttf");
//     src: url("../src/assets/NunitoSans-SemiBold.ttf");
//     src: url("../src/assets/NunitoSans-Bold.ttf");
//     src: url("../src/assets/NunitoSans-ExtraBold.ttf");
//     font-weight:lighter;
// }
  

// fonts
$font-size:16px;
// $font-family: proxima-nova, sans-serif;
// $font-family: "Roboto", sans-serif;
// $font-family: "Lexend", sans-serif;
$font-family: "DM Sans", serif;


// dimensions
$w-100:100%;
$w-75:75%;
$w-50:50%;
$w-25:25%;
$h-100:100%;



*,html,body{
    margin:0px;
    padding:0px;
    box-sizing: border-box;
    font-family: $font-family;
    font-size: $font-size;
}

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 200 to 1000




a{
    text-decoration: none;
}
.w-100{
    width:$w-100;
}
.w-75{
    width:$w-75;
}
.w-50{
    width:$w-50;
}
.w-25{
    width:$w-25;
}
.d-block{
    display: block;
}
.d-flex{
    display: flex;
}
.justify-between{
    justify-content: space-between;
}
.justify-end{
    justify-content:flex-end;
}
.justify-start{
    justify-content:flex-start;
}
.align-center{
    align-items: center;
}
.flex-wrap{
    flex-wrap:wrap;
}
.btn{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding:8px 16px;
    height:56px;
    border:0px;
    cursor: pointer;
    min-height: 45px;
    outline:none !important;
}
.mat-ink-bar{
    display:none;
}
.jobtype_dropdown .mat-select-trigger {
    height:100%;
}
.jobtype_dropdown .mat-form-field-underline{
    display:none;
}
.jobtype_dropdown .mat-form-field-wrapper {
    padding-bottom:0px;
}
.jobtype_dropdown .mat-form-field-infix{
    padding-top:0px;
    border-top:0px;
}
.jobtype_dropdown .dropdown_label{
    margin-bottom: 2px !important;
    display: block;
}
.tabs_div.alljobs{ 
    margin-top:30px;
    .mat-tab-header {
        border-bottom: 1px solid #1A252E !important;
        .mat-tab-labels {
            opacity: 1 !important;
            .mat-tab-label-active {
                border-bottom: 5px solid #0A65CC;
                opacity: 1 !important;
                &:first-child{
                    background: url('../src/assets/images/all_blue.svg') left center no-repeat !important;
                }
                &:nth-child(2){
                    background: url('../src/assets/images/featured_blue.svg') left center no-repeat !important;
                }
                &:nth-child(3){
                    background: url('../src/assets/images/round_plus_blue.svg') left center no-repeat !important;
                }
                .mat-tab-label-content {
                    color: #0A65CC !important;
            }
        }
            .mat-tab-label{
                opacity: 1 !important;
                min-width: 70px !important;
    margin-right: 50px;
    &:first-child{
        background: url('../src/assets/images/all.svg') left center no-repeat;
    }
    &:nth-child(2){
        background: url('../src/assets/images/featured.svg') left center no-repeat;
    }
    &:nth-child(3){
        background: url('../src/assets/images/round_plus.svg') left center no-repeat;
    }
            .mat-tab-label-content{
color:#000000;
font-size: 16px;
font-weight: 400;
    opacity: 1 !important;
    font-family: $font-family;
    margin-left:3px;
            }
        }
        }
    }
}

@media screen and (max-width:575px){
    .cdk-overlay-pane{
        width:100% !important;
        padding:2%;
        top:0px !important;
        left:1% !important;
        .mat-menu-item{
            border-bottom:1px solid #efe8e8; 
            &:hover{
                background-color: #007DD8 !important;
                color:#ffffff !important;
            }
          
        }
    
        .mat-menu-panel{
            width:100% !important;
            max-width: 100% !important;
        }
    }
        .hdr_right.mobile{
            display:flex;
            flex-wrap: wrap;
            justify-content: center;
    margin-bottom:15px !important;
        }
        .register_btn{
            background-color: $action-btn-background;
            color:$white-color;
            border:0px;
            margin-left:0px;
            font-weight: 500;
            font-size: 14px;
            min-height: 45px;
        }
        .refer_btn{
            border:1px solid #34A993;
            color:$white-color;
            font-weight: 500;
            font-size: 14px;
            background-color: #34A993;
            min-height: 45px;
        }
        .login_btn{
            border:1px solid $action-btn-background !important;
            color:$action-btn-background;
            font-weight: 500;
            font-size: 14px;
            background-color: transparent;
            margin-left:0px;
            min-height: 45px;
        }
    }
.accordian_div{
    .mat-expansion-indicator{
        position: relative;
        right:10px;
    }
    .mat-expansion-panel-body {
        padding: 0 0px 0px !important;
    }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: #0A65CC !important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #0A65CC !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #0A65CC !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #0A65CC !important;
}

.desc_txt{
    padding-left: 10px;

    h2{
        padding: 10px 0;
    }

    ul{
        padding-left: 20px;
        padding-bottom: 10px;
    }
}

.referal_form_single{
    .mat-form-field{
        width: 100% !important;
        position: relative;
.mat-form-field-flex{
background-color: transparent !important;
padding: 0px !important;
}
.mat-form-field-underline{
    display: none;
}
.mat-form-field-wrapper{
    padding-bottom: 0px !important;
}
.mat-form-field-infix{
    border-top:0px !important;
    padding:0px !important;
}
    }
}